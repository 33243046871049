import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { BlokkieButton, blokkieTheme } from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import '!file-loader?name=[name].[ext]!../../../images/gifs/cat-computer.gif';
import { OnboardingParagraph } from '../styledComponents';
import OnboardingDropdown from '../Shared/OnboardingDropdown';
import {
  availableBanksFallBackList,
  defaultExcludeFilter,
  BankOption,
} from './constants';
import { InfoScreen } from '../InfoScreen';
import ReferToAbn from './ReferToAbn';
import Loader from '../Loader';
import NavigationHeader from '../components/NavigationHeader';
import {
  AnalyticCategories,
  OnboardingServiceProductType,
  analyticsEvent,
  analyticsProductTypeMapper,
} from '../../../ga4';
import {
  selectHasAbnBank,
  selectOnboardingFlow,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';
import { ABN_AMRO_BANK_CODE } from '../../../containers/OnboardingKYCContainer/config';
import { useOnboardingPageView, useOnboardingTokenAlias } from '../../../hooks';
import { OnboardingFlow } from '../../../containers/OnboardingKYCContainer/onboarding-flow';
import Title from '../Title';
import { setHasAbnBank } from '../../../containers/OnboardingKYCContainer/actions';

function handleIbzAnalytics(
  ibzScreen: boolean,
  selectedBank: string | undefined,
  onboardingFlow: OnboardingFlow,
  productType: OnboardingServiceProductType,
  hasAbnBank: boolean,
  onboardingTokenAlias: string,
) {
  if (!ibzScreen) {
    return;
  }
  analyticsEvent(
    'business-onboarding-UnsupportedBank-page',
    AnalyticCategories.ONBOARDING,
    'onboarding-event',
    {
      bank: selectedBank,
      flow: onboardingFlow,
      product: analyticsProductTypeMapper(productType),
      hasAbnBank,
      onboardingTokenAlias,
    },
  );
}

interface ChooseBankProps {
  nextStep: () => void;
  selectBank: (bank: string) => void;
  previousStep: () => void;
  overruleOnlyAbnOption: boolean;
  selectedBank?: string;
  issuers?: { code: string; name: string }[];
}

function ChooseBank({
  nextStep,
  previousStep,
  selectBank,
  selectedBank,
  overruleOnlyAbnOption,
  issuers,
}: ChooseBankProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const [ibzScreen, setIbzScreen] = useState(false);
  const [info, setInfo] = useState(false);
  const dispatch = useDispatch();
  const productType = useSelector(selectProductType);
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const hasAbnBank = useSelector(selectHasAbnBank);
  const onboardingTokenAlias = useOnboardingTokenAlias();

  // Analytics
  useOnboardingPageView('choose-bank');
  useEffect(
    () =>
      handleIbzAnalytics(
        ibzScreen,
        selectedBank,
        onboardingFlow,
        productType,
        hasAbnBank,
        onboardingTokenAlias,
      ),
    [ibzScreen],
  );

  const availableBanks = useMemo(
    (): BankOption[] =>
      !issuers
        ? availableBanksFallBackList
        : issuers.map((bank: { code: string; name: string }) => ({
            value: bank.code,
            label: bank.name,
          })),
    [issuers],
  );

  const handleChange = (eventBankCode: string) => {
    selectBank(eventBankCode);
    const doesValueMatch = eventBankCode.includes(ABN_AMRO_BANK_CODE);
    dispatch(setHasAbnBank(doesValueMatch));
  };

  if (!issuers) {
    return <Loader />;
  }

  return (
    <>
      {!ibzScreen && (
        <>
          <NavigationHeader
            clickLeft={previousStep as () => void}
            clickRight={() => setInfo(true)}
          />
          <div className={classes.title}>
            <Title>{t('onboarding.chooseBank.title')}</Title>
          </div>
          <OnboardingDropdown
            options={availableBanks.filter(
              (bank) =>
                !defaultExcludeFilter.includes(bank.label.toLowerCase()),
            )}
            placeholder={t('onboarding.chooseBank.placeholder')}
            handleChange={(event) => handleChange(event.target.value)}
            value={
              availableBanks.filter((bank) => bank.value === selectedBank)[0]
                ?.label
            }
            dataTestId="Onboarding-WhichBank-DropDown"
          />
          <div className={classes.buttonRow}>
            <BlokkieButton
              variant="primary"
              size="large"
              disabled={!selectedBank}
              data-test-id="Onboarding-WhichBank-NextButton"
              onClick={
                overruleOnlyAbnOption || selectedBank === ABN_AMRO_BANK_CODE
                  ? nextStep
                  : () => setIbzScreen(true)
              }
            >
              {t('next')}
            </BlokkieButton>
          </div>
        </>
      )}
      {ibzScreen && <ReferToAbn prevStep={() => setIbzScreen(false)} />}
      <InfoScreen
        show={info}
        title={t('onboarding.infoscreenTitle')}
        onClick={() => setInfo(false)}
        fromPage={ChooseBank.name}
      >
        <OnboardingParagraph>
          {t('onboarding.chooseBank.info.body')}
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}

const useStyles = createUseStyles({
  buttonRow: {
    display: 'flex',
    justifyContent: 'right',
  },
  title: {
    marginBottom: blokkieTheme.spacing(3),
  },
});

export default injectIntl(ChooseBank);
