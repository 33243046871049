import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import Generic from './Generic';
import Refer from './Refer';
import Reject from './Reject';
import Login from './Login';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from '../../../ga4';
import {
  selectHasAbnBank,
  selectOnboardingFlow,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';
import RejectWithReferErrorScreen from './RejectWithReferErrorScreen';
import { useOnboardingTokenAlias } from '../../../hooks';

export interface IError {
  referToAbn: boolean;
  errorCode: number;
  path: string;
}

interface IErrorMap {
  login: boolean;
  generic: boolean;
  reject: boolean;
  refer: boolean;
  riskCheckError: boolean;
  failedKvkCheck: boolean;
}

interface ErrorProps {
  error?: IError;
  organisationName: string;
  legalEntity: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearError?: () => void;
}

function Error({
  error,
  organisationName,
  legalEntity,
  clearError,
}: ErrorProps): JSX.Element {
  const history = useHistory();
  const match = useRouteMatch();
  const productType = useSelector(selectProductType);
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const hasAbnBank = useSelector(selectHasAbnBank);
  const onboardingTokenAlias = useOnboardingTokenAlias();
  const riskAnswersPath = '/onboarding/riskanswers';
  const startOnboardingPath = '/onboarding/startorganisationonboarding';

  // 2083 = ERROR_2083_ORGANISATION_ALREADY_EXISTS
  // 2087 = ERROR_2087_ONBOARDING_CHECKS_FAILED
  const errorMap = ({ referToAbn, errorCode, path }: IError): IErrorMap => ({
    login: errorCode === 2083,
    generic:
      errorCode !== 2083 && errorCode !== 2087 && referToAbn === undefined,
    reject: errorCode !== 2083 && referToAbn !== undefined && !referToAbn,
    refer: errorCode !== 2083 && referToAbn !== undefined && referToAbn,
    riskCheckError: errorCode === 2087 && path === riskAnswersPath,
    failedKvkCheck: errorCode === 2087 && path === startOnboardingPath,
  });

  const errorObj = error ? errorMap(error) : undefined;

  const becomeCustomer = (): void => {
    window.location.assign('https://www.abnamro.nl/nl/zakelijk/home.html');
  };

  const mailSupport = (): void => {
    window.location.assign(`mailto:${BUSINESS_EMAIL_ADDRESS}`);
  };

  const goToLoginScreen = (): void => {
    if (clearError) {
      clearError();
    }
    history.push('/login');
  };

  useEffect(() => {
    analyticsEvent('error', AnalyticCategories.ONBOARDING, 'onboarding-event', {
      error_code: error?.errorCode,
      path: match.path,
      flow: onboardingFlow,
      product: analyticsProductTypeMapper(productType),
      hasAbnBank,
      onboardingTokenAlias,
    });
  }, []);

  return (
    <>
      {errorObj?.reject && (
        <Reject organisationName={organisationName} mailSupport={mailSupport} />
      )}
      {errorObj?.refer && (
        <Refer
          organisationName={organisationName}
          legalEntity={legalEntity}
          becomeCustomer={becomeCustomer}
          error={error}
        />
      )}
      {errorObj?.generic && <Generic error={error} mailSupport={mailSupport} />}
      {errorObj?.login && (
        <Login
          organisationName={organisationName}
          error={error}
          login={goToLoginScreen}
        />
      )}
      {(errorObj?.riskCheckError || errorObj?.failedKvkCheck) && (
        <RejectWithReferErrorScreen />
      )}
    </>
  );
}

export default Error;
