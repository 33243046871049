import { push } from 'connected-react-router/immutable';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import config from '../../config';
import { isEmpty } from '../../utils';
import request from '../../utils/request';
import tokenCookie from '../../utils/tokenCookie';
import {
  acceptTermsSuccess,
  checkBusinessPaymentSuccess,
  checkBusinessPaymentSuccessUnfinished,
  checkPersonalPaymentSuccess,
  checkPersonalPaymentSuccessUnfinished,
  clearGenericPending,
  confirmEmailSuccess,
  createTransactionSuccess,
  getCurrentItemSuccess,
  getCurrentItemSuccessWithoutRedirect,
  getIDVStatusSuccess,
  getIssuersSuccess,
  getItemAfterIdvSuccess,
  getPOSDataSuccess,
  getQRCodeSuccess,
  getTermsSuccess,
  initiateBusinessPaymentSuccess,
  initiatePersonalPaymentSuccess,
  resendSmsSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  searchBusinessError,
  searchBusinessSuccess,
  sendBsnSuccess,
  sendEmailSuccess,
  sendRiskAnswersSuccess,
  sendSMSSuccess,
  setAuthorizedToSignSuccess,
  setContactNameSuccess,
  setCountrySuccess,
  setGenericError,
  setGenericErrorWithoutGenericPending,
  setGenericPending,
  setKeepInTouchSuccess,
  setNickNameSuccess,
  setOnboardingTokenAlias,
  setPasswordSuccess,
  setSecondApplicantDetailsSuccess,
  skipBusinessPaymentSuccess,
  startOrganisationOnboardingSuccess,
  submitAddressSuccess,
  submitBadSectorSuccess,
  submitBirthDateSuccess,
  submitPOSDataSuccess,
  submitPartialAddressError,
  submitPartialAddressSuccess,
  submitUboSuccess,
  updateCurrentItemNumber,
  validateSecondApplicantTokenSuccess,
  verifyCodeSuccess,
} from './actions';
import {
  getOnboardingEndpoint,
  getOnboardingPath,
  paymentStatuses,
} from './config';
import {
  ACCEPT_TERMS,
  ACCEPT_TERMS_FOR_SECOND_APPLICANT,
  ACCEPT_TERMS_SUCCESS,
  CHECK_BUSINESS_PAYMENT,
  CHECK_BUSINESS_PAYMENT_SUCCESS,
  CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED,
  CHECK_PERSONAL_PAYMENT,
  CHECK_PERSONAL_PAYMENT_SUCCESS,
  CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED,
  CLEAR_SESSION,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FOR_SECOND_APPLICANT,
  CREATE_TRANSACTION_SUCCESS,
  GET_CURRENT_ITEM,
  GET_CURRENT_ITEM_SUCCESS,
  GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT,
  GET_IDV_STATUS,
  GET_IDV_STATUS_FOR_SECOND_APPLICANT,
  GET_IDV_STATUS_SUCCESS,
  GET_ISSUERS,
  GET_ISSUERS_SUCCESS,
  GET_ITEM_AFTER_IDV,
  GET_ITEM_AFTER_IDV_FOR_SECOND_APPLICANT,
  GET_ITEM_AFTER_IDV_SUCCESS,
  GET_POS_DATA,
  GET_POS_DATA_SUCCESS,
  GET_QR_CODE,
  GET_QR_CODE_SUCCESS,
  GET_TERMS,
  HANDLE_PENDING_PAYMENT,
  INITIATE_BUSINESS_PAYMENT,
  INITIATE_BUSINESS_PAYMENT_SUCCESS,
  INITIATE_PERSONAL_PAYMENT,
  INITIATE_PERSONAL_PAYMENT_SUCCESS,
  RESEND_SMS,
  RESEND_SMS_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SEARCH_BUSINESS,
  SECOND_APPLICANT_DETAILS,
  SECOND_APPLICANT_DETAILS_SUCCESS,
  SEND_BSN,
  SEND_BSN_SUCCESS,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_RISK_ANSWERS,
  SEND_RISK_ANSWERS_SUCCESS,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SET_AUTHORIZED_TO_SIGN,
  SET_AUTHORIZED_TO_SIGN_SUCCESS,
  SET_BIRTH_DATE,
  SET_CONTACT_NAME,
  SET_CONTACT_NAME_SUCCESS,
  SET_COUNTRY,
  SET_COUNTRY_FOR_SECOND_APPLICANT,
  SET_COUNTRY_SUCCESS,
  SET_GENERIC_ERROR,
  SET_KEEP_IN_TOUCH,
  SET_KEEP_IN_TOUCH_SUCCESS,
  SET_NICKNAME,
  SET_NICKNAME_SUCCESS,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_SECTOR,
  SKIP_BUSINESS_PAYMENT,
  SKIP_BUSINESS_PAYMENT_SUCCESS,
  START_ORGANISATION_ONBOARDING,
  START_ORGANISATION_ONBOARDING_SUCCESS,
  SUBMIT_ADDRESS,
  SUBMIT_ADDRESS_FOR_SECOND_APPLICANT,
  SUBMIT_ADDRESS_SUCCESS,
  SUBMIT_BAD_SECTOR,
  SUBMIT_BAD_SECTOR_SUCCESS,
  SUBMIT_BIRTH_DATE,
  SUBMIT_BIRTH_DATE_SUCCESS,
  SUBMIT_PARTIAL_ADDRESS,
  SUBMIT_PARTIAL_ADDRESS_ERROR,
  SUBMIT_PARTIAL_ADDRESS_SUCCESS,
  SUBMIT_PARTIAL_UBO_ADDRESS,
  SUBMIT_POS_DATA,
  SUBMIT_POS_DATA_SUCCESS,
  SUBMIT_UBO,
  SUBMIT_UBO_SUCCESS,
  VALIDATE_SECOND_APPLICANT_TOKEN,
  VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
} from './constants';

export default function* watcherSaga() {
  yield takeLatest(CREATE_TRANSACTION, createTransaction);
  yield takeLatest(GET_CURRENT_ITEM, getCurrentItem);
  yield takeLatest(CONFIRM_EMAIL, confirmEmail);
  yield takeLatest(
    VALIDATE_SECOND_APPLICANT_TOKEN,
    validateSecondApplicantToken,
  );
  yield takeLatest(SEARCH_BUSINESS, searchBusiness);
  yield takeLatest(START_ORGANISATION_ONBOARDING, startOrganisationOnboarding);
  yield takeLatest(SEND_EMAIL, sendEmail);
  yield takeLatest(SEND_RISK_ANSWERS, sendRiskAnswers);
  yield takeLatest(SET_PASSWORD, setPassword);
  yield takeLatest(SUBMIT_BAD_SECTOR, sendBadSector);
  yield takeLatest(SET_CONTACT_NAME, setContactName);
  yield takeLatest(SEND_SMS, sendSMS);
  yield takeLatest(RESEND_SMS, resendSms);
  yield takeLatest(VERIFY_CODE, verifyCode);
  yield takeLatest(SUBMIT_BIRTH_DATE, sendBirthDate);
  yield takeLatest(GET_TERMS, getTerms);
  yield takeLatest(SET_COUNTRY, setCountry);
  yield takeLatest(
    [SUBMIT_PARTIAL_ADDRESS, SUBMIT_PARTIAL_UBO_ADDRESS],
    searchAddress,
  );
  yield takeLatest(SUBMIT_ADDRESS, submitSelectedAddress);
  yield takeLatest(GET_ISSUERS, getIssuers);
  yield takeLatest(SEND_BSN, sendBsn);
  yield takeLatest(ACCEPT_TERMS, acceptTerms);
  yield takeLatest(INITIATE_PERSONAL_PAYMENT, initiatePersonalPayment);
  yield takeLatest(CHECK_PERSONAL_PAYMENT, checkPersonalPayment);
  yield takeLatest(SKIP_BUSINESS_PAYMENT, skipBusinessPayment);
  yield takeLatest(INITIATE_BUSINESS_PAYMENT, initiateBusinessPayment);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(CHECK_BUSINESS_PAYMENT, checkBusinessPayment);
  yield takeLatest(SET_NICKNAME, setNickName);
  yield takeLatest(SET_AUTHORIZED_TO_SIGN, setAuthorizedToSign);
  yield takeLatest(SECOND_APPLICANT_DETAILS, setSecondApplicantDetails);
  yield takeLatest(SUBMIT_UBO, submitUbo);
  yield takeLatest(SUBMIT_POS_DATA, submitPOSData);
  yield takeLatest(SET_KEEP_IN_TOUCH, submitKeepInTouch);
  yield takeLatest(GET_POS_DATA, getPOSData);
  yield takeLatest(GET_QR_CODE, getQRCode);
  yield takeLatest(GET_IDV_STATUS, getIDVStatus);
  yield takeLatest(GET_ITEM_AFTER_IDV, getItemAfterIdv);

  // Next item
  yield takeLatest(
    [
      GET_CURRENT_ITEM_SUCCESS,
      START_ORGANISATION_ONBOARDING_SUCCESS,
      CONFIRM_EMAIL_SUCCESS,
      VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS,
      SET_PASSWORD_SUCCESS,
      SUBMIT_BAD_SECTOR_SUCCESS,
      SET_CONTACT_NAME_SUCCESS,
      SUBMIT_BIRTH_DATE_SUCCESS,
      SET_COUNTRY_SUCCESS,
      SUBMIT_ADDRESS_SUCCESS,
      SEND_BSN_SUCCESS,
      ACCEPT_TERMS_SUCCESS,
      SET_NICKNAME_SUCCESS,
      SET_AUTHORIZED_TO_SIGN_SUCCESS,
      SECOND_APPLICANT_DETAILS_SUCCESS,
      SEND_RISK_ANSWERS_SUCCESS,
      SUBMIT_UBO_SUCCESS,
      SKIP_BUSINESS_PAYMENT_SUCCESS,
      SUBMIT_POS_DATA_SUCCESS,
      CREATE_TRANSACTION_SUCCESS,
      GET_IDV_STATUS_SUCCESS,
      GET_ITEM_AFTER_IDV_SUCCESS,
    ],
    onboardingRoutingSaga,
  );

  // Set jwt token
  yield takeLatest(
    [
      START_ORGANISATION_ONBOARDING_SUCCESS,
      SEND_EMAIL_SUCCESS,
      CONFIRM_EMAIL_SUCCESS,
      VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS,
      SET_PASSWORD_SUCCESS,
      SUBMIT_BIRTH_DATE_SUCCESS,
      SET_CONTACT_NAME_SUCCESS,
      VERIFY_CODE_SUCCESS,
      SUBMIT_BIRTH_DATE_SUCCESS,
      SET_COUNTRY_SUCCESS,
      SUBMIT_ADDRESS_SUCCESS,
      SEND_BSN_SUCCESS,
      ACCEPT_TERMS_SUCCESS,
      INITIATE_PERSONAL_PAYMENT_SUCCESS,
      CHECK_PERSONAL_PAYMENT_SUCCESS,
      CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED,
      SKIP_BUSINESS_PAYMENT_SUCCESS,
      INITIATE_BUSINESS_PAYMENT_SUCCESS,
      CHECK_BUSINESS_PAYMENT_SUCCESS,
      CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED,
      SET_NICKNAME_SUCCESS,
      SET_AUTHORIZED_TO_SIGN_SUCCESS,
      SEND_RISK_ANSWERS_SUCCESS,
      SECOND_APPLICANT_DETAILS_SUCCESS,
      SUBMIT_UBO_SUCCESS,
      CREATE_TRANSACTION_SUCCESS,
      GET_IDV_STATUS_SUCCESS,
      GET_ITEM_AFTER_IDV_SUCCESS,
    ],
    setJwtCookie,
  );

  yield takeLatest(
    [
      GET_CURRENT_ITEM,
      CONFIRM_EMAIL,
      VALIDATE_SECOND_APPLICANT_TOKEN,
      SEND_EMAIL,
      SET_PASSWORD,
      SUBMIT_BAD_SECTOR,
      SET_CONTACT_NAME,
      SEND_SMS,
      RESEND_SMS,
      VERIFY_CODE,
      SUBMIT_BIRTH_DATE,
      SET_COUNTRY,
      SUBMIT_PARTIAL_ADDRESS,
      SUBMIT_PARTIAL_UBO_ADDRESS,
      SUBMIT_ADDRESS,
      GET_ISSUERS,
      SEND_BSN,
      ACCEPT_TERMS,
      INITIATE_PERSONAL_PAYMENT,
      CHECK_PERSONAL_PAYMENT,
      SKIP_BUSINESS_PAYMENT,
      INITIATE_BUSINESS_PAYMENT,
      RESET_PASSWORD,
      CHECK_BUSINESS_PAYMENT,
      SET_NICKNAME,
      SET_AUTHORIZED_TO_SIGN,
      SEND_RISK_ANSWERS,
      SECOND_APPLICANT_DETAILS,
      SUBMIT_UBO,
      SUBMIT_POS_DATA,
      SET_KEEP_IN_TOUCH,
      GET_POS_DATA,
      GET_ITEM_AFTER_IDV,
      CREATE_TRANSACTION,
      GET_QR_CODE,
    ],
    setPending,
  );

  yield takeEvery(
    [
      GET_CURRENT_ITEM_SUCCESS,
      GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT,
      SEND_EMAIL_SUCCESS,
      CONFIRM_EMAIL_SUCCESS,
      VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS,
      SET_PASSWORD_SUCCESS,
      SUBMIT_BIRTH_DATE_SUCCESS,
      SUBMIT_BAD_SECTOR_SUCCESS,
      SET_CONTACT_NAME_SUCCESS,
      VERIFY_CODE_SUCCESS,
      SEND_SMS_SUCCESS,
      RESEND_SMS_SUCCESS,
      SUBMIT_BIRTH_DATE_SUCCESS,
      SET_COUNTRY_SUCCESS,
      GET_ISSUERS_SUCCESS,
      SUBMIT_PARTIAL_ADDRESS_SUCCESS,
      SUBMIT_ADDRESS_SUCCESS,
      SEND_BSN_SUCCESS,
      ACCEPT_TERMS_SUCCESS,
      INITIATE_PERSONAL_PAYMENT_SUCCESS,
      CHECK_PERSONAL_PAYMENT_SUCCESS,
      CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED,
      SKIP_BUSINESS_PAYMENT_SUCCESS,
      INITIATE_BUSINESS_PAYMENT_SUCCESS,
      CHECK_BUSINESS_PAYMENT_SUCCESS,
      CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED,
      SET_GENERIC_ERROR,
      SUBMIT_PARTIAL_ADDRESS_ERROR,
      SET_NICKNAME_SUCCESS,
      SET_AUTHORIZED_TO_SIGN_SUCCESS,
      SECOND_APPLICANT_DETAILS_SUCCESS,
      SEND_RISK_ANSWERS_SUCCESS,
      SUBMIT_UBO_SUCCESS,
      RESET_PASSWORD_SUCCESS,
      SUBMIT_POS_DATA_SUCCESS,
      SET_KEEP_IN_TOUCH_SUCCESS,
      GET_POS_DATA_SUCCESS,
      GET_ITEM_AFTER_IDV_SUCCESS,
      GET_QR_CODE_SUCCESS,
      CREATE_TRANSACTION_SUCCESS,
    ],
    clearPending,
  );

  // Go to ideal payment url
  yield takeLatest(
    [
      INITIATE_PERSONAL_PAYMENT_SUCCESS,
      INITIATE_BUSINESS_PAYMENT_SUCCESS,
      HANDLE_PENDING_PAYMENT,
    ],
    goToIdeal,
  );

  yield takeLatest(CLEAR_SESSION, clearSession);
}

function* genericGet(endPoint, successAction, redirectOnLoginExpiry) {
  const jwt = new Cookies().get('jwt');

  try {
    let response;
    if (isEmpty(jwt)) {
      response = yield call(request, getOnboardingEndpoint(endPoint), {
        headers: {
          'Content-type': 'application/json',
        },
      });
    } else {
      response = yield call(
        request,
        getOnboardingEndpoint(endPoint),
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-type': 'application/json',
          },
        },
        redirectOnLoginExpiry,
      );
    }
    yield put(successAction(response));
  } catch (err) {
    const response = yield call([err.response, 'json']);

    yield put(setGenericError(response));
  }
}

function* genericPost(
  body,
  endPoint,
  successAction,
  redirectOnLoginExpiry,
  errorAction,
) {
  const jwt = new Cookies().get('jwt');
  try {
    let response;
    if (isEmpty(jwt)) {
      response = yield call(request, getOnboardingEndpoint(endPoint), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-type': 'application/json',
        },
      });
    } else {
      response = yield call(
        request,
        getOnboardingEndpoint(endPoint),
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        },
        redirectOnLoginExpiry,
      );
    }
    yield put(successAction(response));
    yield updateCurrentItem(response);
  } catch (err) {
    const response = yield call([err.response, 'json']);
    if (errorAction) {
      yield put(errorAction(response));
    } else {
      yield put(setGenericError(response));
    }
  }
}

function* updateCurrentItem(response) {
  // Always update the currentItemNumber if present
  if (response.currentItemNumber)
    yield put(
      updateCurrentItemNumber({
        currentItemNumber: response.currentItemNumber,
        totalItems: response.totalItems,
      }),
    );

  // Always update the onboardingTokenAlias if present
  if (response.onboardingTokenAlias)
    yield put(setOnboardingTokenAlias(response.onboardingTokenAlias));
}

function* clearSession({ payload }) {
  const cookies = new Cookies();
  cookies.remove('jwt', { path: '/' });

  if (payload.redirectBool) yield put(push('/login'));
}

export function* onboardingRoutingSaga(action) {
  const { currentItem } = action.payload;
  const currentPath = getOnboardingPath(currentItem);

  if (currentPath !== window.location.pathname) {
    yield put(push(currentPath));
  }
}

export function* setJwtCookie(action) {
  tokenCookie(action.payload);
}

export function* clearPending() {
  yield put(clearGenericPending());
}

export function* setPending() {
  yield put(setGenericPending());
}

export function* goToIdeal(action) {
  const { idealPaymentUrl } = action.payload;
  window.location.href = idealPaymentUrl;
}

export function* getCurrentItem(action) {
  const { noRedirect } = action.payload;
  if (noRedirect) {
    yield genericGet(GET_CURRENT_ITEM, getCurrentItemSuccessWithoutRedirect);
  } else {
    yield genericGet(GET_CURRENT_ITEM, getCurrentItemSuccess);
  }
}

export function* getTerms(action) {
  const { termsType, locale } = action.payload;
  const url = `${getOnboardingEndpoint(GET_TERMS)}/${termsType}/${locale}`;
  try {
    const response = yield call(request, url, {
      headers: {
        'Content-type': 'application/json',
      },
    });

    yield put(getTermsSuccess(response));
  } catch (err) {
    const response = yield call([err.response, 'json']);

    yield put(setGenericError(response));
  }
}

export function* getIssuers() {
  yield genericGet(GET_ISSUERS, getIssuersSuccess);
}

export function* getPOSData() {
  yield genericGet(GET_POS_DATA, getPOSDataSuccess);
}

export function* confirmEmail(action) {
  const emailVerificationToken = action.payload;

  yield genericPost(
    { emailVerificationToken },
    CONFIRM_EMAIL,
    confirmEmailSuccess,
  );
}

export function* validateSecondApplicantToken(action) {
  const verificationToken = action.payload;

  yield genericPost(
    { verificationToken },
    VALIDATE_SECOND_APPLICANT_TOKEN,
    validateSecondApplicantTokenSuccess,
  );
}

export function* searchBusiness(action) {
  const requestUrl = `${config.ONBOARDING_SERVICE_URL}/kvk/${encodeURI(
    action.payload,
  )}`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });

    yield put(searchBusinessSuccess(response));
  } catch (err) {
    const statusCode = err.response && err.response.status;
    if (statusCode === 404) {
      yield put(searchBusinessError({ message: err.message, statusCode }));
    } else {
      const response = yield call([err.response, 'json']);
      yield put(setGenericError(response));
    }
  }
}

export function* startOrganisationOnboarding(action) {
  const startOrganisationOnboardingRequest = {
    kvkNumber: action.payload.kvkNumber,
    bankCode: action.payload.bankCode,
    onboardingTokenAlias: action.payload.onboardingTokenAlias,
    aabOnboarding: action.payload.aabOnboarding ?? false,
    productType: action.payload.productType ?? undefined,
    cddOnboarding: action.payload.cddOnboarding ?? false,
  };

  try {
    const response = yield call(
      request,
      getOnboardingEndpoint(START_ORGANISATION_ONBOARDING),
      {
        method: 'POST',
        body: JSON.stringify(startOrganisationOnboardingRequest),
        headers: {
          'Content-type': 'application/json',
        },
      },
    );

    yield put(startOrganisationOnboardingSuccess(response));

    yield updateCurrentItem(response);
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(setGenericErrorWithoutGenericPending(response));
  }
}

export function* setSecondApplicantDetails(action) {
  const details = action.payload;

  yield genericPost(
    details,
    SECOND_APPLICANT_DETAILS,
    setSecondApplicantDetailsSuccess,
  );
}

export function* setAuthorizedToSign(action) {
  const authorizedData = action.payload;

  yield genericPost(
    authorizedData,
    SET_AUTHORIZED_TO_SIGN,
    setAuthorizedToSignSuccess,
  );
}

export function* setNickName(action) {
  const nickName = action.payload;

  yield genericPost({ nickName }, SET_NICKNAME, setNickNameSuccess);
}

export function* sendEmail(action) {
  const email = action.payload;

  yield genericPost({ email }, SEND_EMAIL, sendEmailSuccess, true);
}

export function* sendRiskAnswers(action) {
  const riskAnswers = action.payload;

  yield genericPost(
    { riskAnswers },
    SEND_RISK_ANSWERS,
    sendRiskAnswersSuccess,
    true,
  );
}

export function* submitPOSData(action) {
  const { posSupplier, posSystem } = action.payload;

  yield genericPost(
    { posSupplier, posSystem },
    SUBMIT_POS_DATA,
    submitPOSDataSuccess,
  );
}

export function* submitKeepInTouch(action) {
  const email = action.payload;

  yield genericPost({ email }, SET_KEEP_IN_TOUCH, setKeepInTouchSuccess);
}

export function* setPassword(action) {
  const password = action.payload;

  yield genericPost({ password }, SET_PASSWORD, setPasswordSuccess, true);
}

export function* sendBadSector(action) {
  yield genericPost(
    { badSector: action.payload },
    SET_SECTOR,
    submitBadSectorSuccess,
  );
}

export function* setContactName(action) {
  const contactNameRequest = {
    firstName: action.payload.firstName,
    lastNamePrefix: action.payload.lastNamePrefix,
    lastName: action.payload.lastName,
    gender: action.payload.gender,
  };

  yield genericPost(
    contactNameRequest,
    SET_CONTACT_NAME,
    setContactNameSuccess,
  );
}

export function* sendBirthDate(action) {
  yield genericPost(
    { birthDate: action.payload },
    SET_BIRTH_DATE,
    submitBirthDateSuccess,
  );
}

export function* sendBsn({ payload }) {
  yield genericPost({ bsn: payload }, SEND_BSN, sendBsnSuccess);
}

export function* sendSMS(action) {
  const mobile = `+316${action.payload}`;

  yield genericPost({ phoneNumber: mobile }, SEND_SMS, sendSMSSuccess, true);
}

export function* resendSms(action) {
  const mobile = `+316${action.payload}`;
  yield genericPost(
    { phoneNumber: mobile },
    RESEND_SMS,
    resendSmsSuccess,
    true,
  );
}

export function* verifyCode(action) {
  yield genericPost(
    { verificationCode: action.payload },
    VERIFY_CODE,
    verifyCodeSuccess,
    true,
  );
}

export function* setCountry(action) {
  yield genericPost(
    { country: action.payload.countryCode },
    action.payload.forSecondApplicant
      ? SET_COUNTRY_FOR_SECOND_APPLICANT
      : SET_COUNTRY,
    setCountrySuccess,
  );
}

export function* searchAddress(action) {
  const requestUrl = getOnboardingEndpoint(SUBMIT_PARTIAL_ADDRESS);
  const jwt = new Cookies().get('jwt');

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    yield put(submitPartialAddressSuccess(response));
  } catch (err) {
    const statusCode = err.response && err.response.status;
    if (statusCode === 404) {
      yield put(
        submitPartialAddressError({ message: err.message, statusCode }),
      );
    } else {
      const response = yield call([err.response, 'json']);
      yield put(setGenericError(response));
    }
  }
}

export function* submitSelectedAddress(action) {
  yield genericPost(
    action.payload.address,
    action.payload.forSecondApplicant
      ? SUBMIT_ADDRESS_FOR_SECOND_APPLICANT
      : SUBMIT_ADDRESS,
    submitAddressSuccess,
  );
}

export function* submitUbo(action) {
  yield genericPost(action.payload, SUBMIT_UBO, submitUboSuccess);
}

export function* acceptTerms(action) {
  yield genericPost(
    {},
    action.payload.forSecondApplicant
      ? ACCEPT_TERMS_FOR_SECOND_APPLICANT
      : ACCEPT_TERMS,
    acceptTermsSuccess,
  );
}

export function* initiatePersonalPayment(action) {
  const issuerCode = action.payload;
  yield genericPost(
    { issuerCode },
    INITIATE_PERSONAL_PAYMENT,
    initiatePersonalPaymentSuccess,
  );
}

export function* skipBusinessPayment() {
  yield genericPost({}, SKIP_BUSINESS_PAYMENT, skipBusinessPaymentSuccess);
}

export function* initiateBusinessPayment(action) {
  const issuerCode = action.payload;
  yield genericPost(
    { issuerCode },
    INITIATE_BUSINESS_PAYMENT,
    initiateBusinessPaymentSuccess,
  );
}

export function* checkPersonalPayment(action) {
  const paymentToken = action.payload;
  const jwt = new Cookies().get('jwt');
  const url = getOnboardingEndpoint(CHECK_PERSONAL_PAYMENT);

  try {
    const response = yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({ paymentToken }),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const status = response.paymentStatus;
    if (status === paymentStatuses.paid) {
      yield put(checkPersonalPaymentSuccess(response));
      yield updateCurrentItem(response);
    } else {
      yield put(checkPersonalPaymentSuccessUnfinished(response));
      yield updateCurrentItem(response);
    }
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(setGenericError(response));
  }
}

export function* resetPasswordSaga(action) {
  const { password, token } = action.payload;
  const url = getOnboardingEndpoint(RESET_PASSWORD);

  try {
    yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({ password, resetPasswordToken: token }),
      headers: {
        'Content-type': 'application/json',
      },
    });
    yield put(resetPasswordSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(resetPasswordSuccess());
    } else {
      const response = yield call([err.response, 'json']);
      yield put(resetPasswordError(response));
    }
  }
}

export function* checkBusinessPayment(action) {
  const paymentToken = action.payload;
  const jwt = new Cookies().get('jwt');
  const url = getOnboardingEndpoint(CHECK_BUSINESS_PAYMENT);

  try {
    const response = yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({ paymentToken }),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const status = response.paymentStatus;
    if (status === paymentStatuses.paid) {
      yield put(checkBusinessPaymentSuccess(response));
      yield updateCurrentItem(response);
    } else {
      yield put(checkBusinessPaymentSuccessUnfinished(response));
      yield updateCurrentItem(response);
    }
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(setGenericError(response));
  }
}

export function* getQRCode(action) {
  const requestUrl = `${config.QR_SERVICE_URL}/qrcode`;

  const body = {
    url: action.payload.idvStartUrl,
    size: 30,
    style: 'round',
  };
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    yield put(getQRCodeSuccess(response.qrCodeImage));
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(setGenericError(response));
  }
}

export function* createTransaction(action) {
  const requestUrl = getOnboardingEndpoint(
    action.payload.forSecondApplicant
      ? CREATE_TRANSACTION_FOR_SECOND_APPLICANT
      : CREATE_TRANSACTION,
  );
  const jwt = new Cookies().get('jwt');
  const data = {
    type: action.payload.type,
  };
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });
    yield put(createTransactionSuccess(response));
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(setGenericError(response));
  }
}

export function* getIDVStatus(action) {
  const requestUrl = getOnboardingEndpoint(
    action.payload.forSecondApplicant
      ? GET_IDV_STATUS_FOR_SECOND_APPLICANT
      : GET_IDV_STATUS,
  );
  const jwt = new Cookies().get('jwt');
  try {
    const response = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.idvStatus === 'ERROR') {
      yield put(setGenericErrorWithoutGenericPending(response));
    } else {
      yield put(getIDVStatusSuccess(response));
    }
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(setGenericErrorWithoutGenericPending(response));
  }
}

export function* getItemAfterIdv(action) {
  const { transactionId } = action.payload;
  const url = `${getOnboardingEndpoint(
    action.payload.forSecondApplicant
      ? GET_ITEM_AFTER_IDV_FOR_SECOND_APPLICANT
      : GET_ITEM_AFTER_IDV,
  )}/${transactionId}`;

  try {
    const response = yield call(request, url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    });

    yield put(getItemAfterIdvSuccess(response));
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(setGenericError(response));
  }
}
