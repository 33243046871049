import {
  ACCEPT_TERMS,
  ACCEPT_TERMS_SUCCESS,
  CHECK_BUSINESS_PAYMENT,
  CHECK_BUSINESS_PAYMENT_SUCCESS,
  CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED,
  CHECK_PERSONAL_PAYMENT,
  CHECK_PERSONAL_PAYMENT_SUCCESS,
  CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED,
  CLEAR_GENERIC_ERROR,
  CLEAR_GENERIC_PENDING,
  CLEAR_SESSION,
  CLEAR_TRANSACTION_PROPS,
  CLEAR_UBO_ADDRESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  GET_CURRENT_ITEM,
  GET_CURRENT_ITEM_SUCCESS,
  GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT,
  GET_IDV_STATUS,
  GET_IDV_STATUS_SUCCESS,
  GET_ISSUERS,
  GET_ISSUERS_SUCCESS,
  GET_ITEM_AFTER_IDV,
  GET_ITEM_AFTER_IDV_SUCCESS,
  GET_POS_DATA,
  GET_POS_DATA_SUCCESS,
  GET_QR_CODE,
  GET_QR_CODE_SUCCESS,
  GET_TERMS,
  GET_TERMS_SUCCESS,
  HANDLE_PENDING_PAYMENT,
  INITIATE_BUSINESS_PAYMENT,
  INITIATE_BUSINESS_PAYMENT_SUCCESS,
  INITIATE_PERSONAL_PAYMENT,
  INITIATE_PERSONAL_PAYMENT_SUCCESS,
  RESEND_SMS,
  RESEND_SMS_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_TOKEN,
  RESET_RESET_PASSWORD,
  SAVE_FIRSTNAME,
  SAVE_PHONE_NUMBER,
  SEARCH_BUSINESS,
  SEARCH_BUSINESS_ERROR,
  SEARCH_BUSINESS_RESET,
  SEARCH_BUSINESS_SUCCESS,
  SECOND_APPLICANT_DETAILS,
  SECOND_APPLICANT_DETAILS_SUCCESS,
  SELECT_BANK,
  SELECT_COUNTRY,
  SEND_BSN,
  SEND_BSN_SUCCESS,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SET_AAB_ONBOARDING,
  SET_CDD_ONBOARDING,
  SET_APPLICANT_2_NAME,
  SET_AUTHORIZED_TO_SIGN,
  SET_AUTHORIZED_TO_SIGN_SUCCESS,
  SET_BAD_SECTOR,
  SET_BIRTH_DATE,
  SET_BUSINESS_PAYMENT_TOKEN,
  SET_CONTACT_NAME,
  SET_CONTACT_NAME_SUCCESS,
  SET_COUNTRY,
  SET_COUNTRY_SUCCESS,
  SET_EXPAND_ONBOARDING_KYC_CONTAINER,
  SET_GENERIC_ERROR,
  SET_GENERIC_ERROR_WITHOUT_GENERIC_PENDING,
  SET_GENERIC_PENDING,
  SET_INITIAL_GET_CURRENT_ITEM,
  SET_IS_SECOND_APPLICANT,
  SET_KEEP_IN_TOUCH,
  SET_KEEP_IN_TOUCH_SUCCESS,
  SET_NICKNAME,
  SET_NICKNAME_SUCCESS,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PERSONAL_PAYMENT_TOKEN,
  SET_POS_SUPPLIER,
  SET_PRODUCT_TYPE,
  SET_ONBOARDING_FLOW,
  SET_UBO_ADDRESS,
  SET_UBO_BIRTH_DATE,
  SET_UBO_COUNTRY,
  SET_UBO_NAME,
  SKIP_BUSINESS_PAYMENT,
  SKIP_BUSINESS_PAYMENT_SUCCESS,
  START_ADD_UBO,
  START_EDIT_UBO,
  START_ORGANISATION_ONBOARDING,
  START_ORGANISATION_ONBOARDING_SUCCESS,
  SUBMIT_ADDRESS,
  SUBMIT_ADDRESS_SUCCESS,
  SUBMIT_BAD_SECTOR,
  SUBMIT_BAD_SECTOR_SUCCESS,
  SUBMIT_BIRTH_DATE,
  SUBMIT_BIRTH_DATE_SUCCESS,
  SUBMIT_PARTIAL_ADDRESS,
  SUBMIT_PARTIAL_ADDRESS_ERROR,
  SUBMIT_PARTIAL_ADDRESS_SUCCESS,
  SUBMIT_PARTIAL_UBO_ADDRESS,
  SUBMIT_POS_DATA,
  SUBMIT_POS_DATA_SUCCESS,
  SUBMIT_UBO,
  SUBMIT_UBO_SUCCESS,
  UPDATE_CURRENT_ITEM_NUMBER,
  VALIDATE_SECOND_APPLICANT_TOKEN,
  VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  SEND_RISK_ANSWERS,
  SEND_RISK_ANSWERS_SUCCESS,
  SET_HAS_ABN_BANK,
  SET_ONBOARDING_TOKEN_ALIAS,
} from './constants';

export function setExpandOnboardingKYCContainer(expand) {
  return {
    type: SET_EXPAND_ONBOARDING_KYC_CONTAINER,
    payload: { expand },
  };
}

export function getCurrentItem(noRedirect) {
  return {
    type: GET_CURRENT_ITEM,
    payload: { noRedirect },
  };
}

export function getCurrentItemSuccess(response) {
  return {
    type: GET_CURRENT_ITEM_SUCCESS,
    payload: { ...response },
  };
}

export function getCurrentItemSuccessWithoutRedirect(response) {
  return {
    type: GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT,
    payload: { ...response },
  };
}

export function confirmEmail(token) {
  return {
    type: CONFIRM_EMAIL,
    payload: token,
  };
}

export function confirmEmailSuccess(response) {
  return {
    type: CONFIRM_EMAIL_SUCCESS,
    payload: { ...response },
  };
}

export function validateSecondApplicantToken(token) {
  return {
    type: VALIDATE_SECOND_APPLICANT_TOKEN,
    payload: token,
  };
}

export function validateSecondApplicantTokenSuccess(response) {
  return {
    type: VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS,
    payload: { ...response },
  };
}

export function searchBusiness(kvkNumber) {
  return {
    type: SEARCH_BUSINESS,
    payload: kvkNumber,
  };
}

export function searchBusinessSuccess(response) {
  return {
    type: SEARCH_BUSINESS_SUCCESS,
    payload: response,
  };
}

export function searchBusinessError(error) {
  return {
    type: SEARCH_BUSINESS_ERROR,
    payload: error,
  };
}

export function resetSearchResult() {
  return {
    type: SEARCH_BUSINESS_RESET,
  };
}

export function selectBank(bankCode) {
  return {
    type: SELECT_BANK,
    payload: { bankCode },
  };
}

export function setOnboardingTokenAlias(uuid) {
  return {
    type: SET_ONBOARDING_TOKEN_ALIAS,
    payload: { uuid },
  };
}

export function startOrganisationOnboarding(payload) {
  return {
    type: START_ORGANISATION_ONBOARDING,
    payload,
  };
}

export function startOrganisationOnboardingSuccess(response) {
  return {
    type: START_ORGANISATION_ONBOARDING_SUCCESS,
    payload: { ...response },
  };
}

export function sendEmail(email) {
  return {
    type: SEND_EMAIL,
    payload: email,
  };
}

export function sendEmailSuccess(response) {
  return {
    type: SEND_EMAIL_SUCCESS,
    payload: { ...response },
  };
}

export function setPassword(password) {
  return {
    type: SET_PASSWORD,
    payload: password,
  };
}

export function setPasswordSuccess(response) {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: response,
  };
}

export function setBadSector(badSectorBool) {
  return {
    type: SET_BAD_SECTOR,
    payload: badSectorBool,
  };
}

export function submitBadSector(badSector) {
  return {
    type: SUBMIT_BAD_SECTOR,
    payload: badSector,
  };
}

export function submitBadSectorSuccess(reponse) {
  return {
    type: SUBMIT_BAD_SECTOR_SUCCESS,
    payload: reponse,
  };
}

export function saveFirstName(firstName) {
  return {
    type: SAVE_FIRSTNAME,
    payload: firstName,
  };
}

export function setContactName(contactNameData) {
  return {
    type: SET_CONTACT_NAME,
    payload: contactNameData,
  };
}

export function setContactNameSuccess(response) {
  return {
    type: SET_CONTACT_NAME_SUCCESS,
    payload: response,
  };
}

export function sendSMS(mobile) {
  return {
    type: SEND_SMS,
    payload: mobile,
  };
}

export function sendSMSSuccess(response) {
  return {
    type: SEND_SMS_SUCCESS,
    payload: response,
  };
}

export function savePhoneNumber(mobile) {
  return {
    type: SAVE_PHONE_NUMBER,
    payload: mobile,
  };
}

export function resendSms(mobile) {
  return {
    type: RESEND_SMS,
    payload: mobile,
  };
}

export function resendSmsSuccess(response) {
  return {
    type: RESEND_SMS_SUCCESS,
    payload: response,
  };
}

export function verifyCode(code) {
  return {
    type: VERIFY_CODE,
    payload: code,
  };
}

export function verifyCodeSuccess(response) {
  return {
    type: VERIFY_CODE_SUCCESS,
    payload: response,
  };
}

export function getTerms(termsType) {
  return {
    type: GET_TERMS,
    payload: { termsType, locale: 'nl_NL' },
  };
}

export function getTermsSuccess(payload) {
  return {
    type: GET_TERMS_SUCCESS,
    payload: { payload },
  };
}

export function setBirthDate(birthDate) {
  return {
    type: SET_BIRTH_DATE,
    payload: birthDate,
  };
}

export function submitBirthDate(birthDate) {
  return {
    type: SUBMIT_BIRTH_DATE,
    payload: birthDate,
  };
}

export function submitBirthDateSuccess(reponse) {
  return {
    type: SUBMIT_BIRTH_DATE_SUCCESS,
    payload: reponse,
  };
}

export function selectCountryFromList(countryCode) {
  return {
    type: SELECT_COUNTRY,
    payload: countryCode,
  };
}

export function setCountry(countryCode, forSecondApplicant) {
  return {
    type: SET_COUNTRY,
    payload: { countryCode, forSecondApplicant },
  };
}

export function setCountrySuccess(response) {
  return {
    type: SET_COUNTRY_SUCCESS,
    payload: response,
  };
}

export function submitPartialAddress(address) {
  return {
    type: SUBMIT_PARTIAL_ADDRESS,
    payload: address,
  };
}

export function submitPartialAddressError() {
  return {
    type: SUBMIT_PARTIAL_ADDRESS_ERROR,
  };
}

export function submitPartialAddressSuccess(response) {
  return {
    type: SUBMIT_PARTIAL_ADDRESS_SUCCESS,
    payload: response,
  };
}

export function submitAddress(address, forSecondApplicant) {
  return {
    type: SUBMIT_ADDRESS,
    payload: {
      address,
      forSecondApplicant,
    },
  };
}

export function submitAddressSuccess(response) {
  return {
    type: SUBMIT_ADDRESS_SUCCESS,
    payload: response,
  };
}

export function getIssuers() {
  return {
    type: GET_ISSUERS,
  };
}

export function getIssuersSuccess(response) {
  return {
    type: GET_ISSUERS_SUCCESS,
    payload: response,
  };
}

export function getPOSData() {
  return {
    type: GET_POS_DATA,
  };
}

export function getPOSDataSuccess(response) {
  return {
    type: GET_POS_DATA_SUCCESS,
    payload: response,
  };
}

export function sendBsn(bsn) {
  return {
    type: SEND_BSN,
    payload: bsn,
  };
}

export function sendBsnSuccess(response) {
  return {
    type: SEND_BSN_SUCCESS,
    payload: response,
  };
}

export function updateCurrentItemNumber(response) {
  return {
    type: UPDATE_CURRENT_ITEM_NUMBER,
    payload: response,
  };
}

export function acceptTerms(forSecondApplicant) {
  return {
    type: ACCEPT_TERMS,
    payload: { forSecondApplicant },
  };
}

export function acceptTermsSuccess(response) {
  return {
    type: ACCEPT_TERMS_SUCCESS,
    payload: response,
  };
}

export function initiatePersonalPayment(issuerCode) {
  return {
    type: INITIATE_PERSONAL_PAYMENT,
    payload: issuerCode,
  };
}

export function initiatePersonalPaymentSuccess(response) {
  return {
    type: INITIATE_PERSONAL_PAYMENT_SUCCESS,
    payload: response,
  };
}

export function checkPersonalPayment(paymentToken) {
  return {
    type: CHECK_PERSONAL_PAYMENT,
    payload: paymentToken,
  };
}

export function checkPersonalPaymentSuccess(response) {
  return {
    type: CHECK_PERSONAL_PAYMENT_SUCCESS,
    payload: response,
  };
}

export function checkPersonalPaymentSuccessUnfinished(response) {
  return {
    type: CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED,
    payload: response,
  };
}

export function handlePendingPayment(idealPaymentUrl) {
  return {
    type: HANDLE_PENDING_PAYMENT,
    payload: { idealPaymentUrl },
  };
}

export function setPersonalPaymentToken(paymentToken) {
  return {
    type: SET_PERSONAL_PAYMENT_TOKEN,
    payload: paymentToken,
  };
}

export function skipBusinessPayment() {
  return {
    type: SKIP_BUSINESS_PAYMENT,
  };
}

export function skipBusinessPaymentSuccess(response) {
  return {
    type: SKIP_BUSINESS_PAYMENT_SUCCESS,
    payload: response,
  };
}

export function initiateBusinessPayment(issuerCode) {
  return {
    type: INITIATE_BUSINESS_PAYMENT,
    payload: issuerCode,
  };
}

export function initiateBusinessPaymentSuccess(response) {
  return {
    type: INITIATE_BUSINESS_PAYMENT_SUCCESS,
    payload: response,
  };
}

export function setGenericError(error) {
  return {
    type: SET_GENERIC_ERROR,
    payload: error,
  };
}

export function setGenericErrorWithoutGenericPending(error) {
  return {
    type: SET_GENERIC_ERROR_WITHOUT_GENERIC_PENDING,
    payload: error,
  };
}

export function clearGenericError() {
  return {
    type: CLEAR_GENERIC_ERROR,
  };
}

export function setGenericPending() {
  return {
    type: SET_GENERIC_PENDING,
  };
}

export function clearGenericPending() {
  return {
    type: CLEAR_GENERIC_PENDING,
  };
}

export function resetPassword(password, token) {
  return {
    type: RESET_PASSWORD,
    payload: {
      password,
      token,
    },
  };
}

export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error,
  };
}

export function setResetPasswordToken(token) {
  return {
    type: RESET_PASSWORD_TOKEN,
    payload: { token },
  };
}

export function resetResetPassword() {
  return {
    type: RESET_RESET_PASSWORD,
  };
}

export function checkBusinessPayment(paymentToken) {
  return {
    type: CHECK_BUSINESS_PAYMENT,
    payload: paymentToken,
  };
}

export function checkBusinessPaymentSuccess(response) {
  return {
    type: CHECK_BUSINESS_PAYMENT_SUCCESS,
    payload: response,
  };
}

export function checkBusinessPaymentSuccessUnfinished(response) {
  return {
    type: CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED,
    payload: response,
  };
}

export function setBusinessPaymentToken(paymentToken) {
  return {
    type: SET_BUSINESS_PAYMENT_TOKEN,
    payload: paymentToken,
  };
}

export function setNickName(nickName) {
  return {
    type: SET_NICKNAME,
    payload: nickName,
  };
}

export function setNickNameSuccess(response) {
  return {
    type: SET_NICKNAME_SUCCESS,
    payload: response,
  };
}

export function setAuthorizedToSign(authorizedData) {
  return {
    type: SET_AUTHORIZED_TO_SIGN,
    payload: authorizedData,
  };
}

export function setAuthorizedToSignSuccess(response) {
  return {
    type: SET_AUTHORIZED_TO_SIGN_SUCCESS,
    payload: response,
  };
}

export function clearSession(redirectBool) {
  return {
    type: CLEAR_SESSION,
    payload: { redirectBool },
  };
}

export function submitUbo(action, ubo) {
  return {
    type: SUBMIT_UBO,
    payload: {
      action,
      ubo,
    },
  };
}

export function submitUboSuccess(response) {
  return {
    type: SUBMIT_UBO_SUCCESS,
    payload: response,
  };
}

export function startAddUbo() {
  return {
    type: START_ADD_UBO,
  };
}

export function startEditUbo(ubo) {
  return {
    type: START_EDIT_UBO,
    payload: ubo,
  };
}

export function setUboName(uboNameData) {
  return {
    type: SET_UBO_NAME,
    payload: uboNameData,
  };
}

export function setUboBirthDate(birthDate) {
  return {
    type: SET_UBO_BIRTH_DATE,
    payload: birthDate,
  };
}

export function setUboCountry(country) {
  return {
    type: SET_UBO_COUNTRY,
    payload: country,
  };
}

export function clearUboAddress() {
  return {
    type: CLEAR_UBO_ADDRESS,
  };
}

export function setUboAddress(address) {
  return {
    type: SET_UBO_ADDRESS,
    payload: address,
  };
}
export function submitPartialUboAddress(address) {
  return {
    type: SUBMIT_PARTIAL_UBO_ADDRESS,
    payload: address,
  };
}

export function setInitialGetCurrentItem(boolean) {
  return {
    type: SET_INITIAL_GET_CURRENT_ITEM,
    payload: boolean,
  };
}

export function setPOSSupplier(posSupplier) {
  return {
    type: SET_POS_SUPPLIER,
    payload: posSupplier,
  };
}

export function submitPOSData(posSupplier, posSystem) {
  return {
    type: SUBMIT_POS_DATA,
    payload: {
      posSupplier,
      posSystem,
    },
  };
}

export function submitPOSDataSuccess(response) {
  return {
    type: SUBMIT_POS_DATA_SUCCESS,
    payload: response,
  };
}

export function setKeepInTouch(email) {
  return {
    type: SET_KEEP_IN_TOUCH,
    payload: email,
  };
}

export function setKeepInTouchSuccess(response) {
  return {
    type: SET_KEEP_IN_TOUCH_SUCCESS,
    payload: response,
  };
}

export function setAabOnboarding(aabOnboarding) {
  return {
    type: SET_AAB_ONBOARDING,
    payload: aabOnboarding,
  };
}

export function setCddOnboarding(cddOnboarding) {
  return {
    type: SET_CDD_ONBOARDING,
    payload: cddOnboarding,
  };
}

export function setSecondApplicant(isSecondApplicant) {
  return {
    type: SET_IS_SECOND_APPLICANT,
    payload: isSecondApplicant,
  };
}

export function setProductType(productType) {
  return {
    type: SET_PRODUCT_TYPE,
    payload: productType,
  };
}

export function setOnboardingFlow(flowType) {
  return {
    type: SET_ONBOARDING_FLOW,
    payload: flowType,
  };
}

export function setHasAbnBank(hasAbnBank) {
  return {
    type: SET_HAS_ABN_BANK,
    payload: hasAbnBank,
  };
}

export function getQRCode(idvStartUrl) {
  return {
    type: GET_QR_CODE,
    payload: {
      idvStartUrl,
    },
  };
}

export function getQRCodeSuccess(response) {
  return {
    type: GET_QR_CODE_SUCCESS,
    payload: {
      data: response,
    },
  };
}

export function createTransaction(type, forSecondApplicant) {
  return {
    type: CREATE_TRANSACTION,
    payload: {
      type,
      forSecondApplicant,
    },
  };
}

export function createTransactionSuccess(response) {
  return {
    type: CREATE_TRANSACTION_SUCCESS,
    payload: response,
  };
}

export function getIDVStatus(forSecondApplicant) {
  return {
    type: GET_IDV_STATUS,
    payload: {
      forSecondApplicant,
    },
  };
}

export function getIDVStatusSuccess(data) {
  return {
    type: GET_IDV_STATUS_SUCCESS,
    payload: data,
  };
}

export function getItemAfterIdv(transactionId, forSecondApplicant) {
  return {
    type: GET_ITEM_AFTER_IDV,
    payload: { transactionId, forSecondApplicant },
  };
}

export function getItemAfterIdvSuccess(response) {
  return {
    type: GET_ITEM_AFTER_IDV_SUCCESS,
    payload: { ...response },
  };
}

export function clearTransactionProps() {
  return {
    type: CLEAR_TRANSACTION_PROPS,
  };
}

export function setApplicant2Name(name) {
  return {
    type: SET_APPLICANT_2_NAME,
    payload: name,
  };
}

export function setSecondApplicantDetails(name, email) {
  return {
    type: SECOND_APPLICANT_DETAILS,
    payload: { name, email },
  };
}

export function setSecondApplicantDetailsSuccess(response) {
  return {
    type: SECOND_APPLICANT_DETAILS_SUCCESS,
    payload: response,
  };
}

export function sendRiskAnswers(riskAnswers) {
  return {
    type: SEND_RISK_ANSWERS,
    payload: riskAnswers,
  };
}

export function sendRiskAnswersSuccess(response) {
  return {
    type: SEND_RISK_ANSWERS_SUCCESS,
    payload: { ...response },
  };
}
