/* eslint-disable jsx-a11y/anchor-is-valid */
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/titanic-man.mp4';
import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import ShortDisturbanceMessage from '../../SDM';
import ButtonFooter from '../components/ButtonFooter';
import { CenterWrapper } from '../styledComponents';

import CostRectangles from '../Agreement/CostRectangles';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from '../../../ga4';
import {
  selectHasAbnBank,
  selectOnboardingFlow,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';
import {
  productTypeToLokaliseTag,
  tikkieBusiness,
  tikkieRecycling,
} from '../../../containers/OnboardingKYCContainer/config';
import { useOnboardingPageView, useOnboardingTokenAlias } from '../../../hooks';
import Title from '../Title';
import { InfoScreen } from '../InfoScreen';

export const ONBOARDING_ENTRY_SCREEN_DESCRIPTION_TEST_ID =
  'onboarding-entry-screen-description-test-id';

export const ONBOARDING_ENTRY_SCREEN_AAB_ACCOUNT_LANDING_DESCRIPTION_TEST_ID =
  'onboarding-entry-screen-landing-aab-account-description-test-id';

export interface OnboardingEntryProps {
  nextStep: () => void;
  goToLoginScreen: () => void;
  aabOnboarding: boolean;
  isAabAccountLandingPage: boolean;
}

function OnboardingEntry({
  nextStep,
  goToLoginScreen,
  aabOnboarding,
  isAabAccountLandingPage,
}: OnboardingEntryProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const productType = useSelector(selectProductType);
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const hasAbnBank = useSelector(selectHasAbnBank);
  const onboardingTokenAlias = useOnboardingTokenAlias();

  useOnboardingPageView(undefined, 'business-onboarding-OnboardingEntry-page');

  const [values, setValues] = useState({
    showCosts: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const productTag = productTypeToLokaliseTag.get(
    productType || tikkieBusiness,
  )!;

  const toggleShowCosts = (): void => {
    setValues({ ...values, showCosts: !values.showCosts });
  };

  const extraTag =
    aabOnboarding && productTag === 'business' ? '.aabonboarding' : '';

  const copyForField = (field: string) =>
    isAabAccountLandingPage
      ? t(`onboarding.startscreen.landingpage.aabaccount.${field}`)
      : t(`onboarding.startscreen.${productTag}${extraTag}.${field}`);

  const idForDescription = () =>
    isAabAccountLandingPage
      ? ONBOARDING_ENTRY_SCREEN_AAB_ACCOUNT_LANDING_DESCRIPTION_TEST_ID
      : ONBOARDING_ENTRY_SCREEN_DESCRIPTION_TEST_ID;

  return (
    <>
      <CenterWrapper>
        {goToLoginScreen && (
          <div
            className={classes.loginLink}
            onClick={goToLoginScreen}
            aria-hidden="true"
          >
            {t('login')}
          </div>
        )}
        <ShortDisturbanceMessage context="business-onboarding-start" isInner />
        <BlokkieGifContainer
          source="/titanic-man.mp4"
          gifOffset="-25px"
          style={{
            marginTop: `${blokkieTheme.spacing(4)}px`,
            marginBottom: `${blokkieTheme.spacing()}px`,
          }}
        />
        <div className={classes.paragraph}>
          <Title textAlign="center">{copyForField('title')}</Title>
          <BlokkieTypography
            data-test-id={idForDescription()}
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              textAlign: 'center',
              lineHeight: '1.5',
              whiteSpace: 'pre-wrap',
              marginBottom: `${blokkieTheme.spacing(2)}px`,
            }}
            variant="pl500"
          >
            <>
              {aabOnboarding ? (
                <div> {copyForField('description')} </div>
              ) : (
                copyForField('description')
              )}

              {[tikkieBusiness, tikkieRecycling].includes(productType) && (
                <BlokkieTextLink
                  variant="pl700"
                  onClick={() => toggleShowCosts()}
                  underline
                  color={blokkieTheme.colors.grey.veryDark}
                >
                  {t('onboarding.startscreen.link')}
                </BlokkieTextLink>
              )}
            </>
          </BlokkieTypography>
        </div>
        <ButtonFooter>
          <BlokkieButton
            onClick={() => {
              analyticsEvent(
                'business-onboarding-OnboardingEntry-start-button',
                AnalyticCategories.ONBOARDING,
                'onboarding-event',
                {
                  flow: onboardingFlow,
                  product: analyticsProductTypeMapper(productType),
                  hasAbnBank,
                  onboardingTokenAlias,
                },
              );

              nextStep();
            }}
            data-test-id="onboarding-startscreen-nextButton"
            variant="primary"
            size="large"
            style={{
              marginBottom: blokkieTheme.spacing(2),
            }}
          >
            {copyForField('button')}
          </BlokkieButton>
        </ButtonFooter>
      </CenterWrapper>

      <InfoScreen
        show={values.showCosts}
        title={t(`onboarding.costs.${productTag}.title`)}
        onClick={toggleShowCosts}
        fromPage={CostRectangles.name}
      >
        <CostRectangles />
      </InfoScreen>
    </>
  );
}

const useStyles = createUseStyles({
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  loginLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontFamily: 'MuseoSansRounded700',
    fontSize: 16,
    color: blokkieTheme.colors.primary.green,
    cursor: 'pointer',
    userSelect: 'none',
    textDecoration: 'none',
    alignSelf: 'flex-end',
  },
});

export default OnboardingEntry;
