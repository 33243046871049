import React from 'react';
import { injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/confused-man.mp4';
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/confetti-man.mp4';

import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import { CenterWrapper } from '../styledComponents';
import Loader from '../Loader';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import { useOnboardingPageView } from '../../../hooks';
import ButtonFooter from '../components/ButtonFooter';
import Title from '../Title';

interface EmailConfirmProps {
  nextStep: () => void;
  confirmEmailSuccess: boolean;
  resendEmailResult: {
    success: boolean;
    pending: boolean;
  };
  expiredToken: boolean;
  resendEmail: (param: string) => void;
  email: string;
  getCurrentItem: (param: boolean) => void;
}

function EmailConfirm({
  nextStep,
  confirmEmailSuccess,
  resendEmailResult,
  expiredToken,
  resendEmail,
  email,
  getCurrentItem,
}: EmailConfirmProps): JSX.Element {
  if (resendEmailResult.pending) return <Loader />;

  if (expiredToken && email === '') {
    getCurrentItem(true);
  }

  if (expiredToken && email !== '') {
    resendEmail(email);
  }

  // send the page view when the email or resend is successful,
  // to use the correct onboardingTokenAlias
  if (confirmEmailSuccess || resendEmailResult.success) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useOnboardingPageView('email-confirm');
  }

  return (
    <CenterWrapper>
      {confirmEmailSuccess && (
        <>
          <BlokkieGifContainer
            gifOffset="-20%"
            source="../../confetti-man.mp4"
            style={{
              marginTop: `${blokkieTheme.spacing(4)}px`,
              marginBottom: `${blokkieTheme.spacing(2)}px`,
            }}
          />
          <Title textAlign="center">
            {t('onboarding.email.emailConfirmed.title')}
          </Title>
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              textAlign: 'center',
              marginTop: blokkieTheme.spacing(1),
              marginBottom: blokkieTheme.spacing(1),
            }}
            variant="pl500"
          >
            {t('onboarding.email.emailConfirmed.description')}
          </BlokkieTypography>
          <ButtonFooter>
            <BlokkieButton
              variant="primary"
              size="large"
              onClick={nextStep}
              data-test-id="Onboarding-EmailVerification-GoNextButton"
            >
              {t('onboarding.email.emailConfirmed.passwordChooseButton')}
            </BlokkieButton>
          </ButtonFooter>
        </>
      )}
      {resendEmailResult.success && (
        <>
          <BlokkieGifContainer
            source="../../../confused-man.mp4"
            style={{
              marginTop: `${blokkieTheme.spacing(4)}px`,
              marginBottom: `${blokkieTheme.spacing(2)}px`,
            }}
          />
          <Title textAlign="center">
            {t('onboarding.email.linkNotWorking.title')}
          </Title>
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              textAlign: 'center',
              marginTop: blokkieTheme.spacing(1),
              marginBottom: blokkieTheme.spacing(1),
            }}
            variant="pl500"
          >
            {t('onboarding.email.linkNotWorking.descriptionA')}
          </BlokkieTypography>
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              textAlign: 'center',
              marginTop: blokkieTheme.spacing(1),
            }}
            variant="pl500"
          >
            {t('onboarding.email.linkNotWorking.descriptionB')}
          </BlokkieTypography>
        </>
      )}
    </CenterWrapper>
  );
}

export default injectIntl(EmailConfirm);
